<template>
  <div class="container ql-snow-review">
    <div class="content ql-editor-review" v-if="activity_data">
      <div style="width: 375px; height: 269px; margin-bottom: 13px;">
        <img :src="data.top_image" style="width: 375px; height: 269px;"/>
      </div>

      <div style="box-sizing: border-box; padding: 0 25px 0 25px; display: flex; flex-direction: column; align-items: center;">
        <div style="width: 100%; height: fit-content; margin-bottom: 10px;">
          <div style="width: 100%; height: fit-content; display: inline-block; text-align: left; color: #000000; font-size: 11px; line-height: 1.42;" v-html="data.desc"></div>
        </div>

        <div class="activity-state" style="margin-bottom: 25px; width: fit-content; height: fit-content; position: relative; display: flex; justify-content: center;">
          <div class="state-container">
            <div style="width: 100%; height: 29px; position: absolute; top: 4px; display: flex; flex-direction: row; justify-content: center;">
              <div v-for="(item,index) in data.stage" :key="index" style="width: 65px; height: 30px; position: relative;" :style="{'margin-right': (index < data.stage.length - 1 ? 40:0) +'px'}">
                <img :src="item.image" style="width: 65px; height: 30px;"/>
                <img :src="item.image1" style="width: 41px; height: 13px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"/>
              </div>
            </div>

            <div style="width: 100%; height: 30px; position: absolute; top: 30px; display: flex; flex-direction: row; justify-content: center;">
              <div v-for="(item,index) in activityLineSateData" :key="index" :style="{width:item.width+'px', height:item.height+'px'}" style="position: relative; display: flex; flex-direction: row; justify-content: center; align-self: center;">
                <img :src="item.image" :style="{width:item.width+'px', height:item.height+'px'}"/>
              </div>
            </div>

            <div style="width: 100%; height: fit-content; position: absolute; top: 50px; display: flex; flex-direction: row; justify-content: center;">
              <div v-for="(item,index) in data.stage" :key="index" style="width: 100px; position: relative; display: flex; flex-direction: column; justify-content: center; align-self: center; align-items: center; color: #825734; font-size: 11px;">
                <div :class="[item.is_current ? 'active': 'active2']" style="width: 100px; word-break:keep-all; white-space:nowrap;  font-size: 12px; transform: scale(0.8); height: 13px ">{{item.start}}</div>
                <img :src="item.splitImage" mode="aspectFit" style="width: 8px; height: 5px;"/>
                <div :class="[item.is_current ? 'active': 'active2']" style="width: 100px; word-break:keep-all; white-space:nowrap;  font-size: 12px; transform: scale(0.8); height: 13px">{{item.end}}</div>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-bottom: 22px; width: 315px; height: fit-content; position: relative; display: flex; flex-direction: column; justify-content: center; align-items: center;">
          <div style="width: 237px; height: 18px;">
            <img src="./assets/home_title3.png" mode="aspectFit" style="width: 237px; height: 18px;"/>
          </div>

          <div style="margin-top: 15px">
            <div v-for="(item,index) in [1,2,3,4]" :key="index">
              <div class="submission" style="padding: 8px 25px; box-sizing: border-box; display: flex; flex-direction: column">
                <div style="height: 42px; width: 100%; line-height: 42px; position: relative">
                  <div style="width: 100%; text-align: left; color: #6A4221; font-size: 15px; font-weight: 600">作者：少扔垃圾</div>
                  <div style="display: flex; flex-direction: row; position: absolute; right: 7px; top: 0; bottom: 0; align-items: center">
                    <img src="./assets/contribute_icon_like.png" style="width: 16px; height: 16px"/>
                    <div style="color: #DC8D2C; font-size: 11px; margin-left: 4px">668</div>
                    <img src="./assets/contribute_icon_comments.png" style="width: 16px; height: 16px; margin-left: 11px"/>
                    <div style="color: #DC8D2C; font-size: 11px; margin-left: 4px">666</div>
                  </div>
                </div>
                <div style="height: 130px; width: 100%; position: relative">
                  <div style="width: 100%; text-align: left; color: #6A4221; font-size: 13px; margin-top: 10px; font-weight: 600">时弃者</div>
                  <div style="width: 100%; text-align: left; color: #6A4221; font-size: 11px; margin-top: 5px">
                    不与狼队一起睁眼刀人，只知道谁是夺命狼犬，不可自曝，免疫一切夜间伤害。从第二晚开始每晚都可以向一名玩家施放毒雾诅咒使其时间错乱。毒雾效果可以扩散到其左右两边，如果旁边有玩家死亡毒雾也不会继续扩散，当晚1~3名玩家会受到毒雾效果影响。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="data.prize.length > 0" class="activity-reward" style="margin-bottom: 22px; width: 315px; height: fit-content; position: relative; display: flex; flex-direction: column; justify-content: center; align-items: center;">
          <div style="width: 237px; height: 18px; margin-bottom: 8px;">
            <img src="./assets/home_title1.png" mode="aspectFit" style="width: 237px; height: 18px;"/>
          </div>

          <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center; width: 329px">
            <div v-for="(item,index) in data.prize" :key="index" style="width: 50%; margin-bottom: 5px">
              <div style="height: 120px; position: relative; display: flex; flex-direction: column; justify-content: start; align-items: center;">
                <img :src="item.image" mode="aspectFit" style="width: 161px; height: 120px;"/>
                <div style="width: 161px; height: 120px; box-sizing: border-box; padding: 9px 12px 9px 12px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); display: flex; flex-direction: column; justify-content: space-between; align-items: center;">
                  <div style="width: 100%; height: 37px; vertical-align: center; font-weight: bold; font-size: 16px; line-height: 37px; color: #6A4221;">{{item.name}}</div>
                  <div style="box-sizing: border-box;
                            width: 154px;
                            height: 2px;"></div>
                  <div v-html="item.content" style="width: 100%; height: 61px; display: flex; flex-direction: column; justify-content: center"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="data.reviewer.length > 0" class="activity-jury" style="width: 274px; height: fit-content; position: relative; display: flex; flex-direction: column; justify-content: center; align-items: center;">
          <div style="width: 237px; height: 18px; margin-bottom: 12px; ">
            <img src="./assets/home_title2.png" mode="aspectFit" style="width: 237px; height: 18px;"/>
          </div>

          <div v-for="(item,index) in data.reviewer" :key="index" style="margin-bottom: 5px; width: 274px; position: relative; display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <div class="reviewer-bg" :class="[index % 2 == 0 ? 'bg-left': 'bg-right']" >
              <img :src="item.avatar" :style="{'top': (index % 2 == 0 ? 8: 8) +'px', 'left': (index % 2 == 0 ? 8: 200) +'px'}" style="position: absolute; top: 17px; left: 8px; width: 66px; height: 66px; border-radius: 33px; z-index: 200;"/>
            </div>

            <div style="height: 66px; box-sizing: border-box; padding: 0 12px 0 12px; position: absolute; display: flex; flex-direction: column; justify-content: center; align-items: flex-start;" :style="{'left': (index % 2 == 0 ? 75: 0) +'px', 'right': (index % 2 == 0 ? 0: 75) +'px', 'padding-left': (index % 2 == 0 ? 13: 24) +'px', 'padding-right': (index % 2 == 0 ? 24: 13) +'px'}">
              <div style="color: rgba(106, 66, 33, 1); width: 169px; height: fit-content; display: flex; flex-direction: column; justify-content: center; align-items: flex-start; font-weight: bold; font-size: 13px; line-height: 16px; text-align: left;">{{item.name}}</div>
              <div style="color: rgba(106, 66, 33, 1); width: 169px; height: fit-content; display: flex; flex-direction: column; justify-content: center; align-items: flex-start; font-size: 11px; line-height: 14px; text-align: left;">{{item.desc}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "preview_activity",
  props: {
    activity_data: {
      type: Object
    }
  },
  computed: {
    activityLineSateData(){
      let data = [];

      for (let i = 0; i < 5; i++) {
        let info = {}
        if(i % 2 == 1){
          info.image = require('./assets/home_reward_loading_line.png');
          info.splitImage = require('./assets/home_time.png')
          info.width = 96;
          info.height = 2;
          info.startTime = "";
          info.endTime = "";
        }else{
          let stage = this.data.stage[i / 2];
          info.is_current = stage.is_current;
          if(stage.is_current){
            info.image = require('./assets/home_reward_loading_c.png');
            info.splitImage = require('./assets/home_time_red.png')
          }else{
            info.image = require('./assets/home_reward_loading.png');
            info.splitImage = require('./assets/home_time.png')
          }

          info.width = 8;
          info.height = 8;
          info.startTime = stage.start;
          info.endTime = stage.end;
        }

        data[i] = info;
      }

      return data;
    },
  },
  data() {
    return {
      data: {
        top_image: "",
        desc: "",
        stage: [],
        prize: [],
        reviewer: []
      }
    }
  },
  mounted() {
    this.bindData();
  },
  watch: {
    "activity_data":{
      handler:function(val){
        this.bindData();
      },
      immediate:true
    }
  },
  methods: {
    imagePath(str) {
      return require(str);
    },
    bindData() {
      if(!this.activity_data) {
        return;
      }

      this.data.desc = this.activity_data.desc;
      this.data.top_image = this.activity_data.top_image;
      this.data.prize = this.activity_data.prize;
      this.data.reviewer = this.activity_data.reviewer;

      this.data.stage = [
        {
          is_current: this.isCurrentStage(this.activity_data.submit_start, this.activity_data.submit_end),
          start: this.getTimeFormat(this.activity_data.submit_start),
          end: this.getTimeFormat(this.activity_data.submit_end),
          image: this.isCurrentStage(this.activity_data.submit_start, this.activity_data.submit_end) ? require('./assets/home_date_btn1.png') : require('./assets/home_date_btn2.png'),
          image1: this.isCurrentStage(this.activity_data.submit_start, this.activity_data.submit_end) ? require('./assets/home_reward_title1_c.png') : require('./assets/home_reward_title1.png'),
          splitImage: this.isCurrentStage(this.activity_data.submit_start, this.activity_data.submit_end) ? require('./assets/home_time_red.png') : require('./assets/home_time.png')
        },
        {
          is_current: this.isCurrentStage(this.activity_data.vote_start, this.activity_data.vote_end),
          start: this.getTimeFormat(this.activity_data.vote_start),
          end: this.getTimeFormat(this.activity_data.vote_end),
          image: this.isCurrentStage(this.activity_data.vote_start, this.activity_data.vote_end) ? require('./assets/home_date_btn1.png') : require('./assets/home_date_btn2.png'),
          image1: this.isCurrentStage(this.activity_data.vote_start, this.activity_data.vote_end) ? require('./assets/home_reward_title2_c.png') : require('./assets/home_reward_title2.png'),
          splitImage: this.isCurrentStage(this.activity_data.vote_start, this.activity_data.vote_end) ? require('./assets/home_time_red.png') : require('./assets/home_time.png')
        },
        {
          is_current: this.isCurrentStage(this.activity_data.public_start, this.activity_data.public_end),
          start: this.getTimeFormat(this.activity_data.public_start),
          end: this.getTimeFormat(this.activity_data.public_end),
          image: this.isCurrentStage(this.activity_data.public_start, this.activity_data.public_end) ? require('./assets/home_date_btn1.png') : require('./assets/home_date_btn2.png'),
          image1: this.isCurrentStage(this.activity_data.public_start, this.activity_data.public_end) ? require('./assets/home_reward_title3_c.png') : require('./assets/home_reward_title3.png'),
          splitImage: this.isCurrentStage(this.activity_data.public_start, this.activity_data.public_end) ? require('./assets/home_time_red.png') : require('./assets/home_time.png')
        }
      ];
    },
    isCurrentStage(start, end) {
      if(start && start != '' && end && end != '') {
        let now = new Date();
        return now >= start && now <= end;
      }

      return false;
    },
    getTimeFormat(time) {
      if(time && time != '') {
        return time.format('yyyy年MM月dd日')
      }
    }
  }
}
</script>

<style lang="css" src="./assets/activity.css"></style>
<style scoped>
  .container {
    width: 375px;
    height: 667px;
    overflow-y: auto;
    position: relative;
    background-image: url("assets/com_bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #724389;
    background-position: center top;
  }

  .content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 55px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 80;
  }

  .state-container {
    width: 325px;
    height: 95px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: 100% 100%;
    background-image: url('./assets/home_date_bg.png')
  }

  .activity-state {
    width: 325px;
    height: 95px;
  }

  .reviewer-bg {
    width: 274px;
    height: 82px;
    position: relative;
    background-repeat: no-repeat;
  }

  .reviewer-bg.bg-left {
    background-image: url("./assets/home_review_bgleft.png");
    background-size: 274px 82px;
  }

  .reviewer-bg.bg-right {
    background-image: url("./assets/home_review_bgright.png");
    background-size: 274px 82px;
  }

  .active {
    color: #9F4648;
  }

  .active2 {
    color: #825734;
  }

  .submission {
    background-image: url("./assets/contribute_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 333px;
    height: 192px;
  }
</style>
